*{
 font-family: "Poppins",sans-serif;   
}


.carousel0 {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/carousel/carousel0.jpg"); */
  background-image: url("../assets/img/carousel/carousel0.jpg");   
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 1080px; 
   

}

video::-webkit-media-controls {
  display: none;
}


.carousel1 {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/carousel/carousel1.jpg"); */
    background-image: url("../assets/img/carousel/carousel1.jpg");     
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 1080px; 
}


.carousel1 h1{
    margin-left: 50px;
    font-size: 50px;
    margin-bottom: 10px;
    font-weight: bold;
    margin-top: 350px;
}

.carousel1 h3{
    margin-left: 50px;
    font-size: 30px;
    margin-bottom: 10px;
    font-weight: bold;
}

.carousel1 p{
    --animate-delay: 0.5s; 
}


.carousel2 {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/carousel/carousel2.jpg"); */
    background-image: url("../assets/img/carousel/carousel2.jpg");    
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 1080px;   

}

.carousel2 h1{
    margin-left: 50px;
    font-size: 50px;
    margin-bottom: 10px;
    font-weight: bold;
    margin-top: 350px;
}

.carousel2 h3{
  margin-left: 10px;
  font-size: 40px;
  margin-top: 365px; 
  margin-bottom: 10px;
  font-weight: bold;
}

.carousel2 p{
    --animate-delay: 0.5s; 
}

.carousel3 {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/carousel/carousel3.jpg"); */
    background-image: url("../assets/img/carousel/carousel3.jpg");    
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 1080px;   
}

.carousel3 h1{
    margin-left: 50px;
    font-size: 50px;
    margin-bottom: 10px;
    font-weight: bold;
    margin-top: 350px;  
}

.carousel3 h3{
    margin-left: 10px;
    font-size: 40px;
    margin-top: 365px; 
    margin-bottom: 10px;
    font-weight: bold;
}

.carousel3 p{
    --animate-delay: 0.5s; 
}

.carousel4 {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/carousel/carousel4.jpg"); */
  background-image: url("../assets/img/carousel/carousel4.jpg");  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 1080px;   
}

.carousel5 {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/carousel/carousel5.jpg"); */
  background-image: url("../assets/img/carousel/carousel5.jpg");  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 1080px;   
}

.hero {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/hero/contactus.jpg"); */
    background-image: url("../assets/img/hero/contactus.jpg");    
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 650px;   
}

.hero h1{
  padding-top: 550px;
  font-size: 50px;
  text-align: left;
  font-weight: bold;
}


.herocareer {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/hero/career.jpg"); */
    background-image: url("../assets/img/hero/career.jpg");    
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 650px;   
}

.herocareer h1{
  padding-top: 550px;
  font-size: 50px;
  text-align: left;
  font-weight: bold;
}

.heroprofile {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/hero/emncompanyprofile.jpg"); */
    background-image: url("../assets/img/hero/emncompanyprofile.jpg");    
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 650px;
}

.heroprofile img{
  text-align: center;
  font-size: 50px;
  text-align: left;
  font-weight: bold;
}

.heroprofile h1{
  padding-top: 550px;
  font-size: 50px;
  text-align: left;
  font-weight: bold;
}

.heroproduct{
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/hero/product.jpg"); */
  background-image: url("../assets/img/hero/product.jpg");  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 650px;   
}  

.heroproduct h1{
  padding-top: 550px;
  font-size: 50px;
  text-align: left;
  font-weight: bold;
}
 

.heroatramat {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/hero/heroatramat.png"); */
  background-image: url("../assets/img/hero/heroatramat.png");  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 650px;  
   
}

.heroatramatpdx {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/hero/heroatramat.png"); */
  background-image: url("../assets/img/hero/heroatramat.png");  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 650px;  
   
}

.heroatramatpdx h1{
  padding-top: 550px;
  font-size: 50px;
  text-align: left;
  font-weight: bold;
  }

  .heroatramatchromic {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/hero/heroatramat.png"); */
    background-image: url("../assets/img/hero/heroatramat.png");  
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 650px;  
     
  }
  
  .heroatramatchromic h1{
    padding-top: 550px;
    font-size: 50px;
    text-align: left;
    font-weight: bold;
    }


    .heroatramatpga {
      /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/hero/heroatramat.png"); */
      background-image: url("../assets/img/hero/heroatramat.png");  
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      min-height: 650px;  
       
    }
    
    .heroatramatpga h1{
      padding-top: 550px;
      font-size: 50px;
      text-align: left;
      font-weight: bold;
      }    


      .heroatramatpgarapid {
        /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/hero/heroatramat.png"); */
        background-image: url("../assets/img/hero/heroatramat.png");  
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 650px;  
         
      }
      
      .heroatramatpgarapid h1{
        padding-top: 550px;
        font-size: 50px;
        text-align: left;
        font-weight: bold;
        }          

        .heroatramatpgc {
          /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/hero/heroatramat.png"); */
          background-image: url("../assets/img/hero/heroatramat.png");  
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          min-height: 650px;  
           
        }
        
        .heroatramatpgc h1{
          padding-top: 550px;
          font-size: 50px;
          text-align: left;
          font-weight: bold;
          }    

          .heroatramatplaingut {
            /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/hero/heroatramat.png"); */
            background-image: url("../assets/img/hero/heroatramat.png");  
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            min-height: 650px;  
             
          }
          
          .heroatramatplaingut h1{
            padding-top: 550px;
            font-size: 50px;
            text-align: left;
            font-weight: bold;
            }   
            
            .heroatramatnylon {
              /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/hero/heroatramat.png"); */
              background-image: url("../assets/img/hero/heroatramat.png");  
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              min-height: 650px;  
               
            }
            
            .heroatramatnylon h1{
              padding-top: 550px;
              font-size: 50px;
              text-align: left;
              font-weight: bold;
              }            
              
              .heroatramatpolyester {
                /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/hero/heroatramat.png"); */
                background-image: url("../assets/img/hero/heroatramat.png");  
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                min-height: 650px;  
                 
              }
              
              .heroatramatpolyester h1{
                padding-top: 550px;
                font-size: 50px;
                text-align: left;
                font-weight: bold;
                }                 
                                  
                .heroatramatpolypropelene {
                  /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/hero/heroatramat.png"); */
                  background-image: url("../assets/img/hero/heroatramat.png");  
                  background-size: cover;
                  background-position: center;
                  background-repeat: no-repeat;
                  min-height: 650px;  
                   
                }
                
                .heroatramatpolypropelene h1{
                  padding-top: 550px;
                  font-size: 50px;
                  text-align: left;
                  font-weight: bold;
                  }      
                  
                  
.herozepf {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/hero/herozepf.jpg"); */
  background-image: url("../assets/img/hero/herozepf.jpg");  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 650px;   
}

.herogelspon {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/hero/herozepf.jpg"); */
  background-image: url("../assets/img/hero/herogelspon.jpg");  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 650px;   
}


.hero h1{
padding-top: 550px;
font-size: 50px;
text-align: left;
font-weight: bold;
}


.herocustomer {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/hero/customer.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 600px;   
}

.herocustomer h1{
    margin-top: 50px;
    margin-left: 10px;
    font-size: 50px;
    margin-bottom: 10px;
    font-weight: bold;
}


.heroevents {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/hero/events.jpg"); */
    background-image: url("../assets/img/hero/events.jpg");  
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 650px;   
}


.heroevents h1{
  padding-top: 550px;
  font-size: 50px;
  text-align: left;
  font-weight: bold;
}


.officemap1 {
  /* margin-left: 350px; */
  position: relative;
  border: none;
  /* width:auto;
  height:auto; */
}

.officemap2 {
  margin-top: 40px;
  margin-left: 70px;
  margin-bottom: 100px;
}

.navdrop{
  color:white; 
  text-align: left;
  /* background-color:#779b6c; */
  /* -webkit-text-fill-color: white; */
} 

.navdropproduct{
  background-color:orange;
  -webkit-text-fill-color: black;
} 

/* 
#navbar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000080;

} */

/*
 .navmain {
    padding: 10px 0 10px 0;
    background-color: #2478d6;
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    transform-origin: top;
    transition: all 0.25 ease;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;


} 

 .navbar-inverse{
    background-color:  #2478d6;
   }

.navbar.link{
color:  rgb(224, 210, 10);
}   */ 

/* .navmain.color-active  {
    background-color: #212529;
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    padding: 15px 0 15px 0;
} */


/* .navbar {
  background-color: #2478d6;
  padding: 10px 0 10px 0;
    
    
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    transform-origin: top;
    transition: all 0.25 ease;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;


} */
/* 
.navbar link{
color:#000080

} */

/* .navbar.color-active  {
    background-color: #2478d6;
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    padding: 15px 0 15px 0;
}   */


.navprod{
font-size: 20px;
margin-left: 13px;
}

.gallery img{
    height: 220px;
}

.doclicense{
 padding-top: 100px;
 padding-bottom: 100px
}


.doclicense img{
    /* height: 50%;
    width: 70%; */
    margin: auto; 
    display: flex;
    /* height: 150px; */
    /* width: 50px; */
    padding-bottom: 50px;
}

.profiles img{
  /* padding-top: 100px;
  padding-bottom: 10px; */
  height: auto;
  width: 60%;
  margin: auto; 
  display: flex;
  /* height: 150px; */
  /* width: 50px; */
 
}





.doclicense h2{
  font-size: 35px;
    /* padding-top: 5px;
    padding-bottom: 10px; */
      /* color: rgb(0, 128, 73); */
      /* color: rgb(128, 0, 0); */
      /* color: #057195;  */
     color: rgb(128, 0, 0); 
  }

  .doclicense p{
    font-size: 18px;
    }



.doclicense h4{
     padding-top: 5px;
     font-size: 30px;
     color: #000080; 
  }

  .doclicense li{
    font-size: 18px;
    }


  .visioncard{
    padding-top: 150px;
  }  
    
  .doclicense .card{
    border: none;
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    height: 650px; 
    /* background: rgb(255,186,33);
    background: linear-gradient(0deg, rgba(255,186,33,0.5) 0%, rgba(255,186,33,1) 100%); */
      overflow:hidden;
      border-radius:20px;
      min-height:250px;
        box-shadow: 0 0 12px 0 rgba(0,0,0,0.2);
  
      @media (max-width: 768px) {
        min-height:350px;
      }
  
      @media (max-width: 420px) {
        min-height:300px;
      } 
  
      .card-img-overlay {
        transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
      background: rgb(255,186,33);
      background: linear-gradient(0deg, rgba(255,186,33,0.3785889355742297) 0%, #272726 100%);
      }
  
      .card-title{
        font-weight:200;
        font-size:40px;
        text-align: center;
        padding-top: 50px;
      }

      .card-text{
        font-weight:200;
        font-size:20px;
        text-align: center;
        padding-top: 20px;
      }

      .card-meta{color:rgba(0,0,0,0.3);
       text-transform:uppercase;
        font-weight:500;
        letter-spacing:2px;}
  
        &:hover {
        cursor: pointer;
        transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
       }
   }
    
   /* .doclicense .card-img {
     padding-top: 30px;
     height: auto;
     width: 10%;
     margin: auto; 
     display: flex;
   }    */

.profile h1{
  padding-top: 150px;
    /* color: rgb(0, 128, 73); */
    /* color: rgb(128, 0, 0); */
    /* color: #057195;  */
   color: #000080; 
}

.profile p{
    margin-top: 30px;
  }

.profile img{
    height: auto;
    width: 70%;
    margin: auto; 
    display: flex;
    /* height: 150px; */
    /* width: 50px; */
}

.principles h1{
    padding-top: 150px;
    /* color: rgb(0, 128, 73); */
    color: rgb(128, 0, 0);
  }


.principles p{
    margin-top: 30px;
  }

.principles img{
    padding-top: 30px; 
    height: auto;
    width: 100%;
    margin: auto; 
    display: flex;
    /* height: 150px; */
    /* width: 50px; */
}

.picproduct {
   padding-top: 10px; 
  }

.picproduct h2{
    /* padding-top: 150px; */
      /* color: rgb(0, 128, 73); */
      /* color: rgb(128, 0, 0); */
      /* color: #057195;  */
     font-family: Haettenschweiler; 
     font-size: 40px; 
     color: rgb(128, 0, 0); 
  }


.services h1{
    padding-top: 10px;
    /* color: rgb(0, 128, 73); */
    color: rgb(128, 0, 0);
  }

.services p{
    margin-top: 30px;
  }




.product img{
    padding-top: 30px; 
    height: 50%;
    width: 70%;
    margin: auto; 
    display: flex;
    /* height: 150px; */
    /* width: 50px; */
}

.product h1{
    padding-top: 50px;
    /* color: rgb(0, 128, 73); */
    color: rgb(128, 0, 0);
  }

.product p{
    padding-top: 50px;
  }

  .productdetail p{
    padding-top: 40px;
  }

.productdetail h1{
    padding-top: 70px;
    /* color: rgb(0, 128, 73); */
    color: rgb(128, 0, 0);
  }


.productdetail img{
    height: 50%;
    width: 70%;
    margin: auto; 
    display: flex;
    /* height: 150px; */
    /* width: 50px; */
}


.proddesc p{
    padding-top: 10px;
    font-family: Helvetica;
    font-size: 25px;    
    /* align-items: center;
    align-self: center; */
  }

.proddesc h1{
    padding-top: 70px;
    /* color: rgb(0, 128, 73); */
    color: rgb(128, 0, 0);
    font-family: Helvetica;
    font-size: 35px;
  }

  .proddesc {
    text-align: center;
    padding-bottom: 50px;
  }  

  
  .atramatproduct img{
    margin-left: 20px; 
    display: flex; 
    height: 270px; 
    width: 450px;
}

.atramatproduct p{
  margin-left: 28px; 
}


.atramatproduct h1{
  font-size: 40px;
  margin-left: 28px; 
  color: #008B8B;
}

.atramatproduct h4{
  font-size: 20px;
  font-family: Tahoma;
  padding-top: 6px;
  /* margin-left: 28px;  */
  /* color: #939696; */
  color: black;
}



.atramatproduct h5{
  margin-left: 28px; 
  font-size: 18px;
  padding-bottom: 30px;
}


.bonewaxproduct img{
  margin-left: 20px; 
  display: flex; 
  height: 270px; 
  width: 450px;
}

.bonewaxproduct p{
margin-left: 28px; 
}


.bonewaxproduct h1{
font-size: 40px;
margin-left: 28px; 
padding-top: 36px;
color: #008B8B;
}


.bonewaxproduct h4{
  font-size: 20px;
  font-family: Tahoma;
  padding-top: 6px;
  /* margin-left: 28px;  */
  color: #939696;
}

.bonewaxproduct h5{
  margin-left: 28px; 
  font-size: 18px;
  padding-bottom: 30px;
}




.zepfproduct{
  padding-bottom: 70px; 
}

.zepfproduct img{
  margin-left: 20px; 
  display: flex; 
  height: auto; 
  width: 500px;
}


.zepfproduct h1{
  font-size: 40px;
  margin-top: 28px; 
  margin-left: 28px; 
  color: #008B8B;
}

  ul.myUL {
    display: inline-block;
    text-align: left;
  }

  .gelsponproduct{
  padding-top: 70px;
  padding-bottom: 170px;
  }


  .gelsponproduct img{
    margin-left: 20px; 
    display: flex; 
    height: 270px; 
    width: 350px;
}

.gelsponproduct p{
  margin-left: 28px; 
}

.gelsponproduct h6{
  padding-left: 28px; 
}


.gelsponproduct h1{
  font-size: 40px;
  margin-left: 28px; 
  padding-top: 60px;
  color: #008B8B;
}

.gelsponproduct h4{
  font-size: 20px;
  font-family: Tahoma;
  padding-top: 6px;
  /* margin-left: 28px;  */
  /* color: #939696; */
  color: black;
}



.gelsponproduct h5{
  margin-left: 28px; 
  font-size: 18px;
  padding-bottom: 30px;
}

.proddesc img{
    height: auto;
    width: auto;
    padding-bottom: 50px;
    margin: auto; 
    display: flex;
    /* height: 150px; */
    /* width: 50px; */

}


.productlist{
  padding-top: 100px;
  padding-bottom: 700px;

}


.productlist .listgroup{
  padding-top: 280px;
}

.productlist h4{
  font-size: 20px;
  font-family: Tahoma;
  padding-top: 6px;
  /* margin-left: 28px;  */
  color: #939696;
}

.customer img{
    height: 90%;
    width: 70%;
    margin: auto; 
    display: flex;
    padding-top: 50px;
    /* height: 150px; */
    /* width: 50px; */
}







.customer p{
    margin-left: 2px;
    font-size: 17px;
    margin-bottom: 10px;
    text-align: justify;
    padding-top: 50px;
}

.customer h5{
    margin-top: 550px;
    margin-left: 130px;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
}

.contactus{
  padding-top: 35px;
  padding-bottom: 45px;
 }

 
.contactus h4{
    margin-left: 10px;
    color: rgb(71, 73, 72);
  }
  .contactus h5{
    margin-left: 10px;
    /* color: rgb(0, 128, 73); */
    /* color: rgb(128, 0, 0); */
    color: rgb(9, 9, 9);
  }  

.contactus p{
    /* margin-left: 70px;   */
    
    margin-left: 10px;
    font-size: 17px;
    margin-bottom: 10px;   
  }  

  .contactus iframe{
    margin-left: 10px;
    margin-top: 15px;   
  }  


.footer{
    background-color: #212529;
    /* background-color:  #2478d6; */
    /* background-color:  #057195; */

    @media (max-width: 768px) {
      min-height:350px;
    }
    
    @media (max-width: 420px) {
      min-height:300px;
    }

}

.footer .row:nth-child(2)
{
    padding-top: 50px;
}

.comp{
  font-size : 20px;
  margin-left: 20px;
 }

 .comp2{
  font-size : 20px;
  /* margin-top: 370px; */
 }





/* .product button{
    display:flex;
    justify-content:center;
    align-item:center
  } */


  .careerdetail {
    padding-bottom: 50px;
  }  

  .careerdetail img{
    height: auto;
    width: 50%;
    margin: 2px; 
    align-items: center;
    display: flex;
    /* height: 150px; */
    /* width: 50px; */
}

 .careerdetail p{
    margin-left: 2px;
    font-size: 18px;
    margin-bottom: 10px;
    padding-top: 50px;
    /* color: black;  */
    /* list-style: none;*/
}

.careerdetail h1{
    margin-left: 2px;
    font-size: 17px;
    margin-bottom: 10px;
    padding-top: 20px;
    
    /* color: black;  */
    /* list-style: none;*/
}

.careerdetail h2{
    padding-top: 30px;
    /* color: rgb(0, 128, 73); */
    color: rgb(128, 0, 0);
    /* color: black;  */
    /* list-style: none;*/
}

.careerdetail h4{
    /* color: rgb(0, 128, 73); */
    color: rgb(128, 0, 0);
}

/* .careerdetail ul {
    list-style: none;
  }
  ul li::before {
    content: "•";
    color: red;
    font-weight: bold;
    display: inline-block;
    width: 15px;
  } */
 

.careerdetail h5{
    margin-left: 2px;
    font-size: 17px;
    margin-bottom: 10px;
    margin-top: 60px;
}
.careerdetail span{
    /* margin-left: 2px; */
    font-size: 17px;
    /* margin-bottom: 10px; */
    
}

.cardproduct {
    padding-bottom: 100px;
   
}

.listproduct {
  padding-top: 45px;
}

.cardproduct .card {
  height: auto;  
  width: 300px;
}



.cardproduct .card-body .card-title {
    color: #8B0000;
    font-size: .9rem;
    font-weight: 400;
    
    
}




.cardproduct .card-body {
    background-color: #008B8B;
    font-size: .9rem;
    font-weight: 400;
}

.cardproduct button {
    background-color: #8B0000;
    color: whitesmoke;
    font-size: .9rem;
    font-weight: 400;
    width: 250px;
    height: 70px;
}

.custarea1 h4{
    padding-top: 50px;
    /* color: rgb(0, 128, 73); */
    color: rgb(128, 0, 0);
}

.custarea1 ul {
    /* list-style: none; */
    padding-bottom: 50px;
  }

  .custarea2 h4{
    padding-top: 50px;
    /* color: rgb(0, 128, 73); */
    color: rgb(128, 0, 0);
}

.custarea2 ul {
    /* list-style: none; */
    padding-bottom: 50px;
  }  

  /* .cardzerone {
    width: 800px;

  }

  .cardzeronelist {
    height: 300px;

  } */

  /* .cardatramat {
    width: 800px;

  } */

  .cardatramatlist {
    height: 300px;
    width: 1200px;
  } 

  .cardzepflist {
    height: 300px;
    width: 1200px;
  } 

  .cardzepflist {
    height: 300px;

  }

  /* .cardatramat {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0 0px 20px 0 rgba(0,0,0,0.2);
    background-color: rgba(0,0,0,0.2);
    transition: 0.3s;
    height: 280px;
} */

.principalvideo {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/carousel/carousel2.jpg"); */
  background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/carousel/bgvideo.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 50px;
}

.principalcarousel {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/carousel/carousel2.jpg"); */
    background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url("../assets/img/carousel/bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 50px;
    padding-bottom: 50px;
}

.card1 {
    padding-top: 10px;
    padding-left: 100px;
    background-position: center;
    margin: 10px;
  }

  /* .cardaction1 .button {
    padding-left: 10px;
  }   */

  .footer ul {
    list-style: none;
  }

  .events img{
    height: 90%;
    width: 50%;
    margin: auto; 
    display: flex;
    padding-top: 50px;
    /* height: 150px; */
    /* width: 50px; */
}



.cardproduct{
  /* padding-top: 1px; */
  padding-bottom: 100px;
}

.cardproduct .card{
  border: none;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  
  /* background: rgb(255,186,33);
  background: linear-gradient(0deg, rgba(255,186,33,0.5) 0%, rgba(255,186,33,1) 100%); */
 overflow:hidden;
 border-radius:20px;
 margin-top: 40px;
 /* width: 270px; */
 top: 0px;
 left: 50px;
 /* margin-left: 50px; */
 min-height:180px;
   box-shadow: 0 0 12px 0 rgba(0,0,0,0.2);
 
   height: 5px;

 width: 350px;  

 /* @media (max-width: 768px) {
  min-height:350px;
}

@media (max-width: 420px) {
  min-height:300px;
} */


 &.card-has-bg{
 transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  background: rgb(255,186,33);
  background: linear-gradient(0deg, rgba(255,186,33,1) 100%);
  
  background-size:120%;

  background-repeat:no-repeat;
  background-position: center center;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* background: inherit; */
    -webkit-filter: grayscale(1);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);}

  &:hover {
    transform: scale(0.98);
     box-shadow: 0 0 5px -2px rgba(0,0,0,0.3);
    background-size:130%;
     transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);


  }
}


.card-title{font-weight:800}
 .card-meta{color:rgba(0,0,0,0.3);
  text-transform:uppercase;
   font-weight:500;
   letter-spacing:2px;}

 .card-body{ 
   transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
   background: rgb(255,186,33);
   background: linear-gradient(0deg, rgba(255,186,33,0.5) 0%, rgba(255,186,33,1) 100%);

  }
 &:hover {
   .card-body{
     margin-top:30px;
     transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
   }
 cursor: pointer;
 transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}
 .card-img-overlay {
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
 background: rgb(255,186,33);
background: linear-gradient(0deg, rgba(255,186,33,0.3785889355742297) 0%, rgba(255,186,33,1) 100%);
}

}

  
 .newsevents{
  padding-top: 150px;
  padding-bottom: 150px;
 }

  .newsevents .card{
    border: none;
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    
    /* background: rgb(255,186,33);
    background: linear-gradient(0deg, rgba(255,186,33,0.5) 0%, rgba(255,186,33,1) 100%); */
   overflow:hidden;
   border-radius:20px;
   min-height:250px;
     box-shadow: 0 0 12px 0 rgba(0,0,0,0.2);
   
     height: 480px;
  
   @media (max-width: 768px) {
    min-height:350px;
  }
  
  @media (max-width: 420px) {
    min-height:300px;
  }


   &.card-has-bg{
   transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    background: rgb(255,186,33);
    background: linear-gradient(0deg, rgba(255,186,33,1) 100%);
    
    background-size:120%;

    background-repeat:no-repeat;
    background-position: center center;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      /* background: inherit; */
      -webkit-filter: grayscale(1);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);}
  
    &:hover {
      transform: scale(0.98);
       box-shadow: 0 0 5px -2px rgba(0,0,0,0.3);
      background-size:130%;
       transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  

    }
  }
  

  .card-title{font-weight:800}
   .card-meta{color:rgba(0,0,0,0.3);
    text-transform:uppercase;
     font-weight:500;
     letter-spacing:2px;}

   .card-body{ 
     transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
     background: rgb(255,186,33);
     background: linear-gradient(0deg, rgba(255,186,33,0.5) 0%, rgba(255,186,33,1) 100%);
  
    }
   &:hover {
     .card-body{
       margin-top:30px;
       transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
     }
   cursor: pointer;
   transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  }
   .card-img-overlay {
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
   background: rgb(255,186,33);
  background: linear-gradient(0deg, rgba(255,186,33,0.3785889355742297) 0%, rgba(255,186,33,1) 100%);
  }

}


.events{
  padding-top: 100px;
  padding-bottom: 100px;
}


.events h1{
  font-size: 35px;
}

.events p{
  font-size: 30px;
}


.cardprinciples {
  max-width: 300px;
  margin: 10px;
  margin-left: 60px;
  /* padding-top: 10px;
  padding-left: 100px;
  background-position: center;
  margin: 10px; */
}




/* .navtitle {
  background-color: dark;
} */
/* .navtitle:active {
  background-color: blue;
}
 */




/* For Tablet Display */
@media screen and (max-width: 994px){
  .navemn img{
   width : 32px;
   margin-left: 12px;
  }
  

  /* body {
    background-color: yellow;
  } */


  .navprod{
   font-size: 21px;
   margin-left: 13px;
  
   }
   .navimgprod{
     width : 32px;
     margin-left: 12px;
     } 
  
  .title{
   font-size: 16px;
   /* margin-left: 1px; */
   text-align: left;
 
  }
 
  .navdrop{
    color:white; 
    text-align: center;
    /* background-color:#b9b6b6; */
    /* -webkit-text-fill-color: white; */
  } 



 
  /* .navtitle{
   color: white;
   font-weight: bold;
   background-color: red;
 } */
  
  .footer img{
   width : 20px;
   /* margin-left: 12px; */
  }
 
  
 .footer{
   background-color: #212529;
 }
 
 /* .footer .row:nth-child(2)
 {
   padding-top: 10px;
 } */
 
 .comp{
 font-size : 17px;
 margin-left: 10px;
 }
 
 .comp2{
   font-size : 17px;
   /* padding-top: 370px; */
  }
 
  .carousel0 {
   background-image: url("../assets/img/carousel/carousel0.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 400px;
 
 }
 
 
   .carousel1 {
     background-image: url("../assets/img/carousel/carousel1.jpg");
     background-size: cover;
     background-position: center;
     background-repeat: no-repeat;
     min-height: 400px;
 
 }
 
 .carousel1 h1{
   margin-left: 40px;
   font-size: 18px;
   margin-bottom: 10px;
   font-weight: bold;
   margin-top: 80px;
 }
 
 .carousel1 h3{
   margin-left: 40px;
   font-size: 15px;
   margin-bottom: 10px;
   font-weight: bold;
 }
 
 .carousel2 {
   background-image: url("../assets/img/carousel/carousel2.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 400px;
 
 }
 
 .carousel2 h1{
   margin-left: 40px;
   font-size: 18px;
   margin-bottom: 10px;
   font-weight: bold;
   margin-top: 80px;
 }
 
 .carousel2 h3{
   margin-left: 30px;
   font-size: 10px;
   margin-top: 90px;
   margin-bottom: 10px;
   font-weight: bold;
 }
 
 
 .carousel3 {
   background-image: url("../assets/img/carousel/carousel3.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 400px;
 
 }
 
 .carousel3 h1{
   margin-left: 40px;
   font-size: 18px;
   margin-bottom: 10px;
   font-weight: bold;
   margin-top: 80px;
   
 }
 
 .carousel3 h3{
   margin-left: 30px;
   font-size: 10px;
   margin-top: 95px;
   margin-bottom: 10px;
   font-weight: bold;
 }
 
 .carousel4 {
   background-image: url("../assets/img/carousel/carousel4.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 400px;
 
 }
 
 .carousel5 {
   background-image: url("../assets/img/carousel/carousel5.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 400px;
 
 }
 
 .profile h1{
   padding-top: 50px;
     /* color: rgb(0, 128, 73); */
     /* color: rgb(128, 0, 0); */
     /* color: #057195;  */
    color: #000080; 
    font-size: 26px;
 }
 
 .principles h1{
   padding-top: 100px;
   /* color: rgb(0, 128, 73); */
   color: rgb(128, 0, 0);
 }
 
 
 .services h1{
   padding-top: 100px;
   /* color: rgb(0, 128, 73); */
   color: rgb(128, 0, 0);
 }
 
 .principalcarousel {
   background-image: url("../assets/img/carousel/bg.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   padding-top: 50px;
   padding-bottom: 50px;
 }
 
 .cardprinciples {
   max-width: 190px;
   margin: auto;
   
   /* margin-left: 16px; */
 }
 
 
 .hero h1{
   padding-top: 200px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
   .heroproduct{
     background-image: url("../assets/img/hero/product.jpg");
     background-size: cover;
     background-position: center;
     background-repeat: no-repeat;
     min-height: 300px;   
  } 
  
  .heroproduct h1{
   padding-top: 250px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
  .herocareer {
   background-image: url("../assets/img/hero/career.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 300px;   
 } 
 
 .herocareer h1{
   padding-top: 250px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 .hero {
   background-image: url("../assets/img/hero/contactus.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 300px;   
 }
 
 .hero h1{
   padding-top: 250px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 .heroprofile {
   background-image: url("../assets/img/hero/emncompanyprofile.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 300px;   
 }
 
 .heroprofile h1{
   padding-top: 250px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 .heroatramat{
   background-image: url("../assets/img/hero/heroatramat.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 300px;   
 } 
 
 .herozepf{
   background-image: url("../assets/img/hero/herozepf.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 250px;   
 } 
 
 .heroevents {
   background-image: url("../assets/img/hero/events.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 300px;
 }
 
 .heroevents h1{
   padding-top: 250px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 
 .heroatramatpdx {
   background-image: url("../assets/img/hero/heroatramat.png");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 300px;
 }
 
 .heroatramatpdx h1{
   padding-top: 250px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 .heroatramatchromic {
   background-image: url("../assets/img/hero/heroatramat.png");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 300px;
 }
 
 .heroatramatchromic h1{
   padding-top: 250px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 
 
 .heroatramatpga {
   background-image: url("../assets/img/hero/heroatramat.png");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 300px;
 }
 
 .heroatramatpga h1{
   padding-top: 250px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 
 .heroatramatpgarapid {
   background-image: url("../assets/img/hero/heroatramat.png");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 300px;
 }
 
 .heroatramatpgarapid h1{
   padding-top: 250px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 .heroatramatpgc {
   background-image: url("../assets/img/hero/heroatramat.png");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 300px;
 }
 
 .heroatramatpgc h1{
   padding-top: 250px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 
 .heroatramatplaingut {
   background-image: url("../assets/img/hero/heroatramat.png");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 300px;
 }
 
 .heroatramatplaingut h1{
   padding-top: 250px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 
 .heroatramatnylon {
   background-image: url("../assets/img/hero/heroatramat.png");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 300px;
 }
 
 .heroatramatnylon h1{
   padding-top: 250px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 
 .heroatramatpolyester {
   background-image: url("../assets/img/hero/heroatramat.png");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 300px;
 }
 
 .heroatramatpolyester h1{
   padding-top: 250px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 
 .heroatramatpolypropelene {
   background-image: url("../assets/img/hero/heroatramat.png");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 300px;
 }
 
 .heroatramatpolypropelene h1{
   padding-top: 250px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 
 .herozepf {
   background-image: url("../assets/img/hero/herozepf.jpg"); 
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 300px;
 }
 
 .herozepf h1{
   padding-top: 250px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 .herogelspon {
   background-image: url("../assets/img/hero/herogelspon.jpg"); 
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 300px;
 }
 
 .herogelspon h1{
   padding-top: 250px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 
 
 .officemap1 {
   /* margin-left: 50px; */
   width: 255px;
   height: auto; 
   position: relative;
   border: none;
 }
 
 .doclicense{
   padding-top: 50px;
   padding-bottom: 50px;
  }
 
  .doclicense h2{
   font-size: 25px;
  }
 
  .doclicense p{
   font-size: 15px;
  }
 
  .doclicense li{
   font-size: 15px;
  } 
 
  .doclicense h4{
   padding-top: 5px;
   font-size: 20px;
  } 
 
  .visioncard {
   padding-top: 100px;
  }
 
  .visioncard .card{
   height: 750px;
  }
 
 
 
  .visioncard .card .card-title{
   font-size: 30px;
  }
 
  .visioncard .card .card-text{
   font-size: 15px;
  }
 
   .proddesc img{
     height: auto;
     width: 100%;
     padding-bottom: 50px;
     margin: auto; 
     display: flex;    
   }   
 
   .proddesc h1{
     padding-top: 70px;
     /* color: rgb(0, 128, 73); */
     color: rgb(128, 0, 0);
     font-family: Helvetica;
     font-size: 25px;
   }
 
   .proddesc p{
     padding-top: 10px;
     font-family: Helvetica;
     font-size: 20px;    
     /* align-items: center;
     align-self: center; */
   }
 
 
 
   .cardproduct{
     padding-top: 10px;
     padding-bottom: 100px;
   }
   
   .cardproduct .card{
     border: none;
     transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
     
     /* background: rgb(255,186,33);
     background: linear-gradient(0deg, rgba(255,186,33,0.5) 0%, rgba(255,186,33,1) 100%); */
    overflow:hidden;
    border-radius:20px;
    margin-top: 40px;
    /* width: 270px; */
    top: 0px;
    left: 72px;
    /* margin-left: 50px; */
    min-height:180px;
    width: 380px;


      box-shadow: 0 0 12px 0 rgba(0,0,0,0.2);
    
      height: 5px;
   
    /* @media (max-width: 768px) {
     min-height:350px;
   }
   
   @media (max-width: 420px) {
     min-height:300px;
   } */
   
   
    &.card-has-bg{
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
     background: rgb(255,186,33);
     background: linear-gradient(0deg, rgba(255,186,33,1) 100%);
     
     background-size:120%;
   
     background-repeat:no-repeat;
     background-position: center center;
     &:before {
       content: '';
       position: absolute;
       top: 0;
       right: 0;
       bottom: 0;
       left: 0;
       /* background: inherit; */
       -webkit-filter: grayscale(1);
     -moz-filter: grayscale(100%);
     -ms-filter: grayscale(100%);
     -o-filter: grayscale(100%);
     filter: grayscale(100%);}
   
     &:hover {
       transform: scale(0.98);
        box-shadow: 0 0 5px -2px rgba(0,0,0,0.3);
       background-size:130%;
        transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
   
   
     }
   }
   
   
   .card-title{font-weight:800}
    .card-meta{color:rgba(0,0,0,0.3);
     text-transform:uppercase;
      font-weight:500;
      letter-spacing:2px;}
   
    .card-body{ 
      transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
      background: rgb(255,186,33);
      background: linear-gradient(0deg, rgba(255,186,33,0.5) 0%, rgba(255,186,33,1) 100%);
   
     }
    &:hover {
      .card-body{
        margin-top:30px;
        transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
      }
    cursor: pointer;
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
   }
    .card-img-overlay {
     transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    background: rgb(255,186,33);
    background: linear-gradient(0deg, rgba(255,186,33,0.3785889355742297) 0%, rgba(255,186,33,1) 100%);
   }
   
   }
   
 
 
   /* .cardproduct {
     padding-top: 50px;
     padding-left: 1px;
 }
 
 .cardproduct .card-body .card-title {
     color: #8B0000;
     font-size: .9rem;
     font-weight: 400;
 }
 
 .cardproduct .card-body {
     background-color: #008B8B;
     font-size: .9rem;
     font-weight: 400;
 }
 
 .cardproduct button {
     background-color: #8B0000;
     color: whitesmoke;
     font-size: .9rem;
     font-weight: 400;
     width: 250px;
     height: 1px;
 } */
 
 .contactus{
  padding-top: 5px;
  padding-bottom: 45px;
 }
 
 
 .contactus h4{
   font-size: 15px;
   margin-left: 10px;
   color: rgb(71, 73, 72);
 }
 .contactus h5{
   margin-left: 10px;
   /* color: rgb(0, 128, 73); */
   /* color: rgb(128, 0, 0); */
   color: rgb(9, 9, 9);
 }  
 
 .contactus p{
   /* margin-left: 70px;   */
   font-size: 10px;
   margin-left: 10px;
   margin-bottom: 10px;   
 }  
 
 .contactus iframe{
   margin-left: 10px;
   margin-top: 15px;   
 }  


 .listproduct {
  padding-top: 45px;
  width:  190px;
  position: relative;
}
 
 .atramatproduct img{
   margin-left: 20px; 
   margin-top: 1px;
 
   display: flex; 
   height: auto; 
   width: 280px;
 }
 
 .atramatproduct h1{
   font-size: 30px;
   margin-left: 28px; 
   color: #008B8B;
 }
 
 .atramatproduct h5{
   margin-left: 28px; 
   font-size: 17px;
   padding-bottom: 30px;
 }
 
 .atramatproduct ul li{
   font-size: 16px;
 }
 
 .bonewaxproduct img{
   margin-left: 20px; 
   margin-top: 1px;
 
   display: flex; 
   height: auto; 
   width: 280px;
 }
 
 
 .bonewaxproduct h1{
   font-size: 30px;
   margin-left: 28px; 
   color: #008B8B;
 }
 
 .bonewaxproduct h5{
   margin-left: 28px; 
   font-size: 17px;
   padding-bottom: 30px;
 }
 
 
 .bonewaxproduct ul li{
   font-size: 16px;
 }
 
 
 .zepfproduct img{
   margin-left: 20px; 
   display: flex; 
   height: auto; 
   width: 220px;
 }
 
 .zepfproduct h1{
   font-size: 30px;
   margin-top: 28px; 
   margin-left: 28px; 
   color: #008B8B;
 }
 
 
 .gelsponproduct img{
   margin-left: 20px; 
   margin-top: 1px;
 
   display: flex; 
   height: 200px; 
   width: 250px;
 }
 
 
 .gelsponproduct h1{
   font-size: 30px;
   margin-left: 28px; 
   color: #008B8B;
 }
 
 .gelsponproduct h5{
   margin-left: 28px; 
   font-size: 17px;
   padding-bottom: 30px;
 }
 
 
 
 
 /* .productlist .navbar-nav{
  width:auto;
  height:auto;
  text-justify: center;
 } */
 
 /* .navzepf{
   font-size: 1px;
  } */
 
  }
 

  /* For Tablet 7 inch Display */
  /*
 @media screen and (max-width: 1290px){
  .navemn img{
   width : 32px;
   margin-left: 12px;
  }
  

 body {
    background-color: green;
  }

  .principalcarousel {
    background-image: url("../assets/img/carousel/bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .cardprinciples {
    max-width: 20px;
    margin: auto;
   } 
  
} */


/* For HandPhone Display */

@media screen and (max-width: 668px){
  .navemn img{
   width : 32px;
   margin-left: 12px;
  }
  
  /* body {
    background-color: blue;
  } */


  .navprod{
   font-size: 16px;
   margin-left: 13px;
   }
   .navimgprod{
     width : 32px;
     margin-left: 12px;
     } 
  
  .title{
   font-size: 16px;
   margin-left: 1px;
 
  }

  .navdrop{
    color:white; 
    text-align: center;
    /* background-color:#b9b6b6; */
    /* -webkit-text-fill-color: white; */
  } 
 
  .navemn .navlink li a {
    /* styling for non-active links */
    background-color: lightgray;
  }
  
  .navemn .navlink li a.active {
    /* styling for active link */
    background-color: rgb(115, 199, 37);
  }
 
  /* .navtitle{
   color: white;
   font-weight: bold;
   background-color: red;
 } */
  
  .footer img{
   width : 20px;
   /* margin-left: 12px; */
  }
 
  
 .footer{
   background-color: #212529;
 }
 
 /* .footer .row:nth-child(2)
 {
   padding-top: 10px;
 } */
 
 .comp{
 font-size : 17px;
 margin-left: 10px;
 }
 
 .comp2{
   font-size : 17px;
   /* padding-top: 370px;  */
  }
 
  .carousel0 {
   background-image: url("../assets/img/carousel/carousel0.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;
 
 }
 
 
 
 .carousel0 {
   background-image: url("../assets/img/carousel/carousel0.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;
 
 }
 
   .carousel1 {
     background-image: url("../assets/img/carousel/carousel1.jpg");
     background-size: cover;
     background-position: center;
     background-repeat: no-repeat;
     min-height: 200px;
 
 }
 
 .carousel1 h1{
   margin-left: 40px;
   font-size: 18px;
   margin-bottom: 10px;
   font-weight: bold;
   margin-top: 80px;
 }
 
 .carousel1 h3{
   margin-left: 40px;
   font-size: 15px;
   margin-bottom: 10px;
   font-weight: bold;
 }
 
 .carousel2 {
   background-image: url("../assets/img/carousel/carousel2.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;
 
 }
 
 .carousel2 h1{
   margin-left: 40px;
   font-size: 18px;
   margin-bottom: 10px;
   font-weight: bold;
   margin-top: 80px;
 }
 
 .carousel2 h3{
   margin-left: 30px;
   font-size: 10px;
   margin-top: 90px;
   margin-bottom: 10px;
   font-weight: bold;
 }
 
 
 .carousel3 {
   background-image: url("../assets/img/carousel/carousel3.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;
 
 }
 
 .carousel3 h1{
   margin-left: 40px;
   font-size: 18px;
   margin-bottom: 10px;
   font-weight: bold;
   margin-top: 80px;
   
 }
 
 .carousel3 h3{
   margin-left: 30px;
   font-size: 10px;
   margin-top: 95px;
   margin-bottom: 10px;
   font-weight: bold;
 }
 
 .carousel4 {
   background-image: url("../assets/img/carousel/carousel4.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;
 
 }
 
 .carousel5 {
   background-image: url("../assets/img/carousel/carousel5.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;
 
 }
 
 .profile h1{
   padding-top: 50px;
     /* color: rgb(0, 128, 73); */
     /* color: rgb(128, 0, 0); */
     /* color: #057195;  */
    color: #000080; 
    font-size: 26px;
 }
 
 .principles h1{
   padding-top: 100px;
   /* color: rgb(0, 128, 73); */
   color: rgb(128, 0, 0);
 }
 
 
 .services h1{
   padding-top: 100px;
   /* color: rgb(0, 128, 73); */
   color: rgb(128, 0, 0);
 }
 
 .principalcarousel {
   background-image: url("../assets/img/carousel/bg.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   padding-top: 50px;
   padding-bottom: 50px;
 }
 
 .cardprinciples {
   max-width: 190px;
   margin: auto;
   
   /* margin-left: 16px; */
 }
 
 
 .hero h1{
   padding-top: 200px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
   .heroproduct{
     background-image: url("../assets/img/hero/product.jpg");
     background-size: cover;
     background-position: center;
     background-repeat: no-repeat;
     min-height: 200px;   
  } 
  
  .heroproduct h1{
   padding-top: 200px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
  .herocareer {
   background-image: url("../assets/img/hero/career.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;   
 } 
 
 .herocareer h1{
   padding-top: 200px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 .hero {
   background-image: url("../assets/img/hero/contactus.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;   
 }
 
 .hero h1{
   padding-top: 200px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 .heroprofile {
   background-image: url("../assets/img/hero/emncompanyprofile.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;   
 }
 
 .heroprofile h1{
   padding-top: 200px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 .heroatramat{
   background-image: url("../assets/img/hero/heroatramat.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;   
 } 
 
 .herozepf{
   background-image: url("../assets/img/hero/herozepf.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;   
 } 
 
 .heroevents {
   background-image: url("../assets/img/hero/events.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;
 }
 
 .heroevents h1{
   padding-top: 200px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 
 .heroatramatpdx {
   background-image: url("../assets/img/hero/heroatramat.png");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;
 }
 
 .heroatramatpdx h1{
   padding-top: 200px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 .heroatramatchromic {
   background-image: url("../assets/img/hero/heroatramat.png");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;
 }
 
 .heroatramatchromic h1{
   padding-top: 200px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 
 
 .heroatramatpga {
   background-image: url("../assets/img/hero/heroatramat.png");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;
 }
 
 .heroatramatpga h1{
   padding-top: 200px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 
 .heroatramatpgarapid {
   background-image: url("../assets/img/hero/heroatramat.png");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;
 }
 
 .heroatramatpgarapid h1{
   padding-top: 200px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 .heroatramatpgc {
   background-image: url("../assets/img/hero/heroatramat.png");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;
 }
 
 .heroatramatpgc h1{
   padding-top: 200px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 
 .heroatramatplaingut {
   background-image: url("../assets/img/hero/heroatramat.png");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;
 }
 
 .heroatramatplaingut h1{
   padding-top: 200px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 
 .heroatramatnylon {
   background-image: url("../assets/img/hero/heroatramat.png");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;
 }
 
 .heroatramatnylon h1{
   padding-top: 200px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 
 .heroatramatpolyester {
   background-image: url("../assets/img/hero/heroatramat.png");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;
 }
 
 .heroatramatpolyester h1{
   padding-top: 200px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 
 .heroatramatpolypropelene {
   background-image: url("../assets/img/hero/heroatramat.png");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;
 }
 
 .heroatramatpolypropelene h1{
   padding-top: 200px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 
 .herozepf {
   background-image: url("../assets/img/hero/herozepf.jpg"); 
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;
 }
 
 .herozepf h1{
   padding-top: 200px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 .herogelspon {
   background-image: url("../assets/img/hero/herogelspon.jpg"); 
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   min-height: 200px;
 }
 
 .herogelspon h1{
   padding-top: 200px;
   font-size: 20px;
   text-align: left;
   font-weight: bold;
 }
 
 
 
 .officemap1 {
   /* margin-left: 50px; */
   width: 255px;
   height: auto; 
   position: relative;
   border: none;
 }
 
 .doclicense{
   padding-top: 50px;
   padding-bottom: 50px;
  }
 
  .doclicense h2{
   font-size: 25px;
  }
 
  .doclicense p{
   font-size: 15px;
  }
 
  .doclicense li{
   font-size: 15px;
  } 
 
  .doclicense h4{
   padding-top: 5px;
   font-size: 20px;
  } 
 
  .visioncard {
   padding-top: 100px;
  }
 
  .visioncard .card{
   height: 750px;
  }
 
 
 
  .visioncard .card .card-title{
   font-size: 30px;
  }
 
  .visioncard .card .card-text{
   font-size: 15px;
  }
 
   .proddesc img{
     height: auto;
     width: 100%;
     padding-bottom: 50px;
     margin: auto; 
     display: flex;    
   }   
 
   .proddesc h1{
     padding-top: 70px;
     /* color: rgb(0, 128, 73); */
     color: rgb(128, 0, 0);
     font-family: Helvetica;
     font-size: 25px;
   }
 
   .proddesc p{
     padding-top: 10px;
     font-family: Helvetica;
     font-size: 20px;    
     /* align-items: center;
     align-self: center; */
   }
 
 
 
   .cardproduct{
     padding-top: 10px;
     padding-bottom: 100px;
   }
   
   .cardproduct .card{
     border: none;
     transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
     
     /* background: rgb(255,186,33);
     background: linear-gradient(0deg, rgba(255,186,33,0.5) 0%, rgba(255,186,33,1) 100%); */
    overflow:hidden;
    border-radius:20px;
    margin-top: 40px;

    top: 0px;
    left: 10px;
    /* margin-left: 50px; */
    min-height:190px;
      box-shadow: 0 0 12px 0 rgba(0,0,0,0.2);
    
      height: 5px;

    width: 320px;  
   
    /* @media (max-width: 768px) {
     min-height:350px;
   }
   
   @media (max-width: 420px) {
     min-height:300px;
   } */
   
   
    &.card-has-bg{
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
     background: rgb(255,186,33);
     background: linear-gradient(0deg, rgba(255,186,33,1) 100%);
     
     background-size:120%;
   
     background-repeat:no-repeat;
     background-position: center center;
     &:before {
       content: '';
       position: absolute;
       top: 0;
       right: 0;
       bottom: 0;
       left: 0;
       /* background: inherit; */
       -webkit-filter: grayscale(1);
     -moz-filter: grayscale(100%);
     -ms-filter: grayscale(100%);
     -o-filter: grayscale(100%);
     filter: grayscale(100%);}
   
     &:hover {
       transform: scale(0.98);
        box-shadow: 0 0 5px -2px rgba(0,0,0,0.3);
       background-size:130%;
        transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
   
   
     }
   }
   
   
   .card-title{font-weight:800}
    .card-meta{color:rgba(0,0,0,0.3);
     text-transform:uppercase;
      font-weight:500;
      letter-spacing:2px;}
   
    .card-body{ 
      transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
      background: rgb(255,186,33);
      background: linear-gradient(0deg, rgba(255,186,33,0.5) 0%, rgba(255,186,33,1) 100%);
   
     }
    &:hover {
      .card-body{
        margin-top:30px;
        transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
      }
    cursor: pointer;
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
   }
    .card-img-overlay {
     transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    background: rgb(255,186,33);
    background: linear-gradient(0deg, rgba(255,186,33,0.3785889355742297) 0%, rgba(255,186,33,1) 100%);
   }
   
   }
   
 
 
   /* .cardproduct {
     padding-top: 50px;
     padding-left: 1px;
 }
 
 .cardproduct .card-body .card-title {
     color: #8B0000;
     font-size: .9rem;
     font-weight: 400;
 }
 
 .cardproduct .card-body {
     background-color: #008B8B;
     font-size: .9rem;
     font-weight: 400;
 }
 
 .cardproduct button {
     background-color: #8B0000;
     color: whitesmoke;
     font-size: .9rem;
     font-weight: 400;
     width: 250px;
     height: 1px;
 } */
 
 .contactus{
  padding-top: 5px;
  padding-bottom: 45px;
 }
 
 
 .contactus h4{
   font-size: 15px;
   margin-left: 10px;
   color: rgb(71, 73, 72);
 }
 .contactus h5{
   margin-left: 10px;
   /* color: rgb(0, 128, 73); */
   /* color: rgb(128, 0, 0); */
   color: rgb(9, 9, 9);
 }  
 
 .contactus p{
   /* margin-left: 70px;   */
   font-size: 10px;
   margin-left: 10px;
   margin-bottom: 10px;   
 }  
 
 .contactus iframe{
   margin-left: 10px;
   margin-top: 15px;   
 }  


 .listproduct {
  padding-top: 45px;
  width:  290px;
  position: relative;
}
 
 .atramatproduct img{
   margin-left: 20px; 
   margin-top: 1px;
 
   display: flex; 
   height: auto; 
   width: 280px;
 }
 
 .atramatproduct h1{
   font-size: 30px;
   margin-left: 28px; 
   color: #008B8B;
 }
 
 .atramatproduct h5{
   margin-left: 28px; 
   font-size: 17px;
   padding-bottom: 30px;
 }
 
 .atramatproduct ul li{
   font-size: 16px;
 }
 
 .bonewaxproduct img{
   margin-left: 20px; 
   margin-top: 1px;
 
   display: flex; 
   height: auto; 
   width: 280px;
 }
 
 
 .bonewaxproduct h1{
   font-size: 30px;
   margin-left: 28px; 
   color: #008B8B;
 }
 
 .bonewaxproduct h5{
   margin-left: 28px; 
   font-size: 17px;
   padding-bottom: 30px;
 }
 
 
 .bonewaxproduct ul li{
   font-size: 16px;
 }
 
 
 .zepfproduct img{
   margin-left: 20px; 
   display: flex; 
   height: auto; 
   width: 300px;
 }
 
 .zepfproduct h1{
   font-size: 30px;
   margin-top: 28px; 
   margin-left: 28px; 
   color: #008B8B;
 }
 
 
 .gelsponproduct img{
   margin-left: 20px; 
   margin-top: 1px;
 
   display: flex; 
   height: 230px; 
   width: 280px;
 }
 
 
 .gelsponproduct h1{
   font-size: 30px;
   margin-left: 28px; 
   color: #008B8B;
 }
 
 .gelsponproduct h5{
   margin-left: 28px; 
   font-size: 17px;
   padding-bottom: 30px;
 }
 
 
 
 
 /* .productlist .navbar-nav{
  width:auto;
  height:auto;
  text-justify: center;
 } */
 
 /* .navzepf{
   font-size: 1px;
  } */
 
  }
 
 
